import Img from "gatsby-image/withIEPolyfill"
import styled from "styled-components"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const LazyImage = styled(Img).attrs(props => ({
  fluid: props.src,
  objectPosition: props.objectPosition || "50% 50%",
  objectFit: props.cover ? "cover" : "contain",
  loading: "lazy",
}))`
  min-height: ${props =>
    props.minHeight ? `${props.minHeight}px` : "initial"};
  min-width: ${props => (props.minWidth ? `${props.minWidth}px` : "initial")};
  width: ${props => (props.width ? `${props.width}px` : "100%")};
  height: ${props => (props.height ? `${props.height}px` : "100%")};
  max-height: 100%;
  max-width: 100%;
`

export default LazyImage
