import React, { useRef } from "react"
import styled from "styled-components"
import hexToRgba from "../utils/hexToRgba"

// Components
import Swiper from "react-id-swiper"

// Styled
import { Container, media } from "styled-bootstrap-grid"
import LazyImage from "../components/styled/lazyImage"
import { Title, Subtitle } from "../components/styled/typography"
import { SwiperStyles } from "../components/styled/layout"

const HeaderWrapper = styled.header`
  height: 65vh;
  .swiper-button-prev,
  .swiper-button-next {
    text-shadow: 0 0 3px ${({ theme }) => hexToRgba(theme.colors.dark, 0.8)};
    color: white;
  }
`

const HeaderSlide = styled.div`
  position: relative;
  height: 100%;

  background: ${({ theme }) => theme.colors.gray};
  color: white;
`

const HeaderContent = styled(Container)`
  position: absolute;
  z-index: 5;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);

  @media screen and (max-width: 576px) {
    max-width: 75%;
  }
`

const HeaderTitle = styled(Title)`
  margin-bottom: 0.25rem;
  font-size: 2rem;
  color: inherit;
  text-shadow: 0 0 3px ${({ theme }) => hexToRgba(theme.colors.dark, 0.8)};

  ${media.sm`
    font-size: 1.9rem;
  `}

  ${media.md`
    font-size: 2.5rem;
    padding: 0 25px;
  `}

  ${media.lg`
    font-size: 3rem;
  `}
`

const HeaderSubtitle = styled(Subtitle)`
  margin-top: 0;
  color: inherit;
  text-shadow: 0 0 3px ${({ theme }) => hexToRgba(theme.colors.dark, 0.8)};
  ${media.md`
    padding: 0 25px;
  `}
`

const HeaderImage = styled(LazyImage)`
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background: ${({ theme, darken }) => hexToRgba(theme.colors.dark, darken)};
  }
`

const Header = ({ slides, darkBackgroundOpacity, ...props }) => {
  const swiperRef = useRef(null)

  const swipes = slides.map(
    ({ title, subtitle, image, imageAlt, backgroundOpacity }, index) => (
      <HeaderSlide key={index}>
        <HeaderImage
          darken={backgroundOpacity || darkBackgroundOpacity}
          src={image}
          alt={imageAlt}
          cover
        ></HeaderImage>

        <HeaderContent as="hgroup">
          <HeaderTitle uppercase as={index == 0 ? "h1" : "h2"}>
            {title}
          </HeaderTitle>
          <HeaderSubtitle as="h3">{subtitle}</HeaderSubtitle>
        </HeaderContent>
      </HeaderSlide>
    )
  )

  if (slides.length === 1) {
    return <HeaderWrapper {...props}>{swipes[0]}</HeaderWrapper>
  }

  const swiperOptions = {
    slidesPerView: 1,
    loop: true,
    effect: "fade",

    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },

    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },

    autoplay: {
      delay: 2500,
      disableOnInteraction: true,
    },
  }

  return (
    <HeaderWrapper {...props}>
      <SwiperStyles></SwiperStyles>
      <Swiper ref={swiperRef} {...swiperOptions}>
        {swipes}
      </Swiper>
    </HeaderWrapper>
  )
}

Header.defaultProps = {
  slides: [],
  darkBackgroundOpacity: 0.3,
}

export default Header
